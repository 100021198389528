'use strict';
import Mapbox from './includes/mapbox';

document.addEventListener('DOMContentLoaded', () => {
  const menuToggle = document.querySelector('.header__toggle > button');
  const nav = document.querySelector('nav.header__nav');
  let val = true;
  menuToggle.addEventListener('click', () => {
    document.body.classList.toggle('fixed');
    nav.classList.toggle('is-open');
    menuToggle.setAttribute('aria-expanded', val);
    val = !val;
  });
  new Mapbox();
});

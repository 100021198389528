import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export default class Mapbox {
  constructor() {
    this.MapboxContainer = document.getElementById('Mapbox');

    if (this.MapboxContainer) {
      this.init();
    }
  }

  init() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYWx0ZXJpb2RldiIsImEiOiJjbGQ0cTlibXowM2Q0M3ZxbWlqcWdnNzQ4In0.dtFmxFtAnNq0ytGVCmMWcg';

    const map = new mapboxgl.Map({
      container: 'Mapbox', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [4.861145470179916, 52.334952074009536], // starting position [lng, lat]
      zoom: 14, // starting zoom
      projection: 'globe', // display the map as a 3D globe
    });
    const marker = new mapboxgl.Marker({
      color: 'red',
    })
      .setLngLat([4.861145470179916, 52.334952074009536])
      .addTo(map);
    map.on('style.load', () => {
      map.setFog({}); // Set the default atmosphere style
    });
  }
}
